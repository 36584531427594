import { ChangeDetectorRef, Component, OnInit,ElementRef,  ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CallService } from 'src/app/services/call.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from '../../../environments/environment';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FacPopupComponent } from '../fac-popup/fac-popup.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConfirmService } from 'src/app/services/app-confirm/app-confirm.service';

declare var jQuery: any;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  // @ViewChild('myFormPost') myFormPost: ElementRef;
  basicForm: FormGroup;
  public HOST: string = environment.backendUrl;
  signedID : any; // ID DE SIGNED URL - INFORMACION  DE CLIENTE ENVIANDA EN JSON
  token : any; // TOKEN DE SEGURIDAD
  cc : any; // ID DEL TOKEN EN NUESTRA PLATAFORMA
  signature : any; // FIRMA GENERADA PARA ENVIAR
  merchantUrl : any; // URL A LA QUE HAY QUE ENVIAR LA RESPUESTA
  tokenTdc : any ; // TOKEN DE LA TDC QUE SE GENERO
  reference : any ; // REFERENCIA ENVIADA POR EL CLIENTE PARA EL PROCESO
  plan : any; // ID DEL PLAN AL QUE SE ESTA SUSCRIBIENDO EL CLIENTE
  jsonEncoded : any ;// JSON QUE SE ENVIARA AL CLIENTE
  signatureCalculated: any // FIRMA QUE SE GENERA EN BASE AL JSON

  clientForm : FormGroup;


  constructor( private cdr: ChangeDetectorRef,public confirmService: AppConfirmService,private call: CallService,private sanitizer: DomSanitizer,private dialog: MatDialog,private activatedRoute: ActivatedRoute, private spinner : SpinnerService) {
    this.basicForm = new FormGroup({
      numberCreditCard: new FormControl('', [ Validators.required
      ]),
      nameHolderCreditCard : new FormControl('', Validators.required),
      expMonthCreditCard: new FormControl('', Validators.required),
      expYearCreditCard: new FormControl('', Validators.required),
      ccv: new FormControl('', Validators.required)
    });

    this.clientForm = new FormGroup({
      Ds_SignatureVersion: new FormControl('HMAC_SHA256_V1'),
      Ds_MerchantParameters: new FormControl('',Validators.required),
      Ds_Signature: new FormControl('',Validators.required)
    });
   }

  ngOnInit(): void {

    this.spinner.open();

    this.activatedRoute.queryParams.subscribe(querys =>{ // TOMAMOS EL TOKEN DE LA TDC
      this.token = querys.token;
      this.activatedRoute.params.subscribe(param =>{ // TOMAMOS EL ID DEL SIGNED URL QUE TIENE LA INFORMACION EN BD

        this.signedID = param.id;
        this.spinner.close();

      });

    }, err =>{
      console.log(err);
    })


  }

  send(){

    this.spinner.open();

    const data = { creditcard:{
        numberCreditCard : this.basicForm.value.numberCreditCard.trim(),
        nameHolderCreditCard : this.basicForm.value.nameHolderCreditCard,
        expMonthCreditCard : this.basicForm.value.expMonthCreditCard,
        expYearCreditCard : this.basicForm.value.expYearCreditCard,
        ccv : this.basicForm.value.ccv
      },
      signedUrl : this.signedID,
      token : this.token,
      param : 9
    }

    this.call.post(data, this.HOST + "/suscription").subscribe(response =>{
      this.spinner.close();
        if(response.htmlFormData){

          this.cc = response.id;

          let dialogRef: MatDialogRef<any> = this.dialog.open(FacPopupComponent, {
            width: '50%',
            disableClose: true,
            data: { form: this.sanitizer.bypassSecurityTrustHtml(response.htmlFormData), process: this.cc }
          })
          dialogRef.afterClosed()
            .subscribe(res => {
              this.myLoadEvent();
            })

        }else{

          this.myLoadEvent();


        }

        if(this.merchantUrl != null){ // SOLO ENVIAMOS LA RESPUESTA EN CASO DE QUE EL CLIENTE TENGA UNA URL DE RETORNO
          this.jsonEncoded = response.json;
          this.signatureCalculated = response.signature;
          jQuery('#Ds_MerchantParameters').val(this.jsonEncoded);
          jQuery('#Ds_Signature').val(this.signatureCalculated);
          jQuery('#formid').attr('action', this.merchantUrl).submit();

        }

      }, err =>{
        console.log(err);
        this.spinner.close();

        this.confirmService.confirm({title: 'ERROR', message: err})
          .subscribe((result) => {

            if(result){ // SI LA RESPUESTA ES OK - Eliminamos el registro
              console.log("HI");
            }

            this.cdr.markForCheck();
          });

      });

  }


  myLoadEvent(){

    let json : any = {};
    json.reference = this.reference;
    json.plan = this.plan;

    this.call.get(this.call.HOST + "/signedUrl/" + this.signedID).subscribe(signed =>{

      this.jsonEncoded = signed.result.jsonEncoded;
      this.signatureCalculated = signed.result.signatureCalculated;
      this.clientForm.controls['Ds_MerchantParameters'].setValue(signed.result.jsonEncoded);
      this.clientForm.controls['Ds_Signature'].setValue(signed.result.signatureCalculated);

      if(signed.result.result3DS){

        this.confirmService.confirm({title: 'Exito', message: "Registro procesado correctamente"})
        .subscribe((result) => {

          if(result){ // SI LA RESPUESTA ES OK - Eliminamos el registro
            console.log("HI");
          }

          this.cdr.markForCheck();

        });

      }else{
        console.log("Esto es lo que retorna: ", signed.result);
        let messageToShow = "Registro NO procesado";
        this.confirmService.confirm({title: 'ERROR', message: signed.result.rejectionReason != null ? signed.result.rejectionReason : messageToShow})
        .subscribe((result) => {

          if(result){ // SI LA RESPUESTA ES OK - Eliminamos el registro
            console.log("HI");
          }


        this.cdr.markForCheck();
        });

      }

      if(signed.result.merchantUrl != null){ // SOLO ENVIAMOS LA RESPUESTA EN CASO DE QUE EL CLIENTE TENGA UNA URL DE RETORNO

        this.merchantUrl = signed.result.merchantUrl;
        jQuery('#Ds_MerchantParameters').val(this.jsonEncoded);
        jQuery('#Ds_Signature').val(this.signatureCalculated);
        jQuery('#formid').attr('action', this.merchantUrl).submit();

      }

    }, err=>{
      console.log(err);
    })

   }

  onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

}
