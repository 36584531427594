<footer class="footer">
    <div class="container">
      <div class="logo">
        
      </div>
      <div class="rights">
        <img src="/assets/images/power-club-black.svg" alt="Logo">
        Todos los derechos reservados &copy; 2024
      </div>
    </div>
  </footer>
  