<app-header></app-header>
<mat-card class="content">
    <mat-card-content class="formpay">
        <form [formGroup]="basicForm" (ngSubmit)="send()">
            <h1>Completa tu metodo de pago</h1>
            <p>Detalles del pago</p>
            <img src="/assets/images/visa_mastercard.svg" alt="tarjeta de credito">
            <div fxLayout="row wrap">

                <div fxFlex="100" fxFlex.gt-xs="50" class="pr-16">

                    <div class="pb-16">

                        <mat-form-field class="full-width">
                            <input
                            matInput
                            name="nameHolderCreditCard"
                            formControlName="nameHolderCreditCard"
                            placeholder="Titular"
                            value="">
                        </mat-form-field>
                        <small 
                        *ngIf="basicForm.controls['nameHolderCreditCard'].hasError('required') && basicForm.controls['nameHolderCreditCard'].touched" 
                        class="form-error-msg"> Titular es requerido </small>

                    </div>

                    <div class="pb-16">

                        <mat-form-field class="full-width">
                            <mat-label> Año de Expiración </mat-label>
                            <mat-select [formControl]="basicForm.controls['expYearCreditCard']"  >
                                <mat-option value="24">2024</mat-option>
                                <mat-option value="25">2025</mat-option>
                                <mat-option value="26">2026</mat-option>
                                <mat-option value="27">2027</mat-option>
                                <mat-option value="28">2028</mat-option>
                                <mat-option value="29">2029</mat-option>
                                <mat-option value="30">2030</mat-option>
                                <mat-option value="31">2031</mat-option>
                                <mat-option value="32">2032</mat-option>
                                <mat-option value="33">2033</mat-option>
                                <mat-option value="34">2034</mat-option>
                                <mat-option value="35">2035</mat-option>
                                <mat-option value="36">2036</mat-option>
                                <mat-option value="37">2037</mat-option>
                                <mat-option value="38">2038</mat-option>
                                <mat-option value="39">2039</mat-option>
                                <mat-option value="40">2040</mat-option>
                                <mat-option value="41">2041</mat-option>
                                <mat-option value="42">2042</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <small 
                        *ngIf="basicForm.controls['expYearCreditCard'].hasError('required') && basicForm.controls['expYearCreditCard'].touched" 
                        class="form-error-msg"> Año es requerido </small>

                    </div>


                    <div class="pb-16">
                        <mat-form-field class="full-width">
                        <input 
                            maxlength="3"
                            type="password"
                            name="ccv"
                            matInput
                            formControlName="ccv"
                            (keypress)="onlyNumberKey($event)"
                            placeholder="CCV" 
                            value="">
                        </mat-form-field>
                        <small 
                        *ngIf="basicForm.controls['ccv'].hasError('required') && basicForm.controls['ccv'].touched" 
                        class="form-error-msg"> CCV es requerido </small>
                    </div>

               

                    
                </div>

                <div fxFlex="100" fxFlex.gt-xs="50">
                    <div class="pb-16">
                        <mat-form-field class="full-width">
                            <input 
                            (keypress)="onlyNumberKey($event)"
                            matInput
                            name="numberCreditCard"
                            formControlName="numberCreditCard"
                            placeholder="Tarjeta">
                        </mat-form-field>
                        <small 
                        *ngIf="basicForm.controls['numberCreditCard'].hasError('creditCard') && basicForm.controls['numberCreditCard'].touched" 
                        class="form-error-msg"> Tarjeta es requerido </small>
                    </div>


                    <div class="pb-16">

                        <mat-form-field class="full-width">
                            <mat-label> Mes de Expiración </mat-label>
                            <mat-select [formControl]="basicForm.controls['expMonthCreditCard']"  >
                                <mat-option value="01" >ENERO</mat-option>
                                <mat-option value="02" >FEBRERO</mat-option>
                                <mat-option value="03" >MARZO</mat-option>
                                <mat-option value="04" >ABRIL</mat-option>
                                <mat-option value="05" >MAYO</mat-option>
                                <mat-option value="06" >JUNIO</mat-option>
                                <mat-option value="07" >JULIO</mat-option>
                                <mat-option value="08" >AGOSTO</mat-option>
                                <mat-option value="09" >SEPTIEMBRE</mat-option>
                                <mat-option value="10" >OCTUBRE</mat-option>
                                <mat-option value="11" >NOVIEMBRE</mat-option>
                                <mat-option value="12" >DICIEMBRE</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <small 
                        *ngIf="basicForm.controls['expMonthCreditCard'].hasError('required') && basicForm.controls['expMonthCreditCard'].touched" 
                        class="form-error-msg"> Mes es requerido </small>

                    </div>

                </div>
            </div>


            <div fxLayout="row" fxLayoutAlign="end center">
                <button 
                mat-raised-button 
                [disabled]="basicForm.invalid ">Suscribirme</button>
            </div>
           
        </form>
    </mat-card-content>
</mat-card>


<form id="formid" name="form" method="POST" >     
    <input type="hidden" id="Ds_SignatureVersion" name="Ds_SignatureVersion" value="HMAC_SHA256_V1"/>
    <input type="hidden" id="Ds_MerchantParameters" name="Ds_MerchantParameters" value="{{jsonEncoded}}"/>
    <input type="hidden" id="Ds_Signature" name="Ds_Signature" value="{{signatureCalculated}}"/>	
 </form>
 <app-footer></app-footer>
