<header>
    <header class="header">
        <div class="top-bar">
          <div class="container">
            <div class="contact-info">
              <a href="tel:+507 3928100"><i class="fa fa-phone"></i> +507 3928100</a>
            </div>
          </div>
        </div>
        <div class="bottom-bar">
          <div class="container">
            <div class="logo">
              <img src="/assets/images/power-club-black.svg" alt="Logo">
            </div>
            <div class="menu">
              <ul>
                
              </ul>
            </div>
          </div>
        </div>
      </header>
      
  </header>